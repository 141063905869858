import Link from 'next/link'
import OfferShortSpec from './OfferShortSpec'
import { FieldIcon, OfferFeatureGrid } from './OfferFeatureGrid'
import {
	Equipment,
	Firm,
	Installment,
	IsNew,
	Location,
	OfferImage,
	PriceDetails,
	Title,

    extractOfferData,
} from './OfferListItemNewComponents'

import listAlt, { buyCaptionFor } from '@utils/alt/listAlt'
import offerLinkSlugified from '@utils/links/offerLinkSlugified'
import offerLinkBuySlugified from '@utils/links/offerLinkBuySlugified'
import { getAltSpec, getSpec, getBuySpec } from './OfferShortSpec'
import mix from '@utils/styles/mix'

import styles from './OfferListItemNew.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

function OfferListItem(props) {
	const { offer } = props || {}
	if (offer == null) return null
	const isBuy = offer.type === 'buy_ad' || offer.isBuy
    const offerProps = extractOfferData(props)

	return isBuy
        ? <BuyOfferListItem {...offerProps} />
        : <SellOfferListItem {...offerProps} />
}

function SellOfferListItem(offerProps) {
    const {
        featured,
        forList,
        linkWithHost,
        offer,
        inject,

        // possible link props:
        className,
        style,
    } = offerProps

	const link = offerLinkSlugified(offer) // <- TODO:
	const [alt] = listAlt(offer)

	const _className = mix([
		styles[forList ? 'list-item' : 'grid-item'],
		!featured && isHighlighted(offer) && styles.highlighted,
	])

	return (
        (<Link className={className} href={linkWithHost ? HOST + link : link} prefetch={false} style={style}>

            <article className={_className}>
                <OfferImage alt={alt} {...offerProps} />

                <div className={styles.body}>
                    {forList ? (
                        <>
                            <div className={styles['list-body']}>
                                <div className={styles['offer-title']}>
                                    <ResolveTitleLayout offerProps={offerProps} />
                                </div>
                                <div className={styles['price-col']}>
                                    <IsNew offer={offer} {...offerProps} />
                                    <PriceDetails inject={inject} {...offerProps} />
                                    <Installment {...offerProps} />
                                </div>
                            </div>
                            <ResolveSpecLayout offerProps={offerProps} />
                            <hr />
                            <div className={styles['row']}>
                                <Firm {...offerProps} />
                                <Location {...offerProps} />
                            </div>
                        </>
                    ) : (
                        <>
                            <ResolveTitleLayout offerProps={offerProps} />
                            <br />
                            <ResolveSpecLayout offerProps={offerProps} />
                            <br/>
                            <PriceDetails inject={inject} {...offerProps} />
                            <Installment {...offerProps} />
                            <hr />
                            <div className={styles['row']}>
                                <Firm {...offerProps} />
                                <Location {...offerProps} />
                            </div>
                        </>
                    )}
                </div>
            </article>

        </Link>)
    )
}

function ResolveSpecLayout ({ offerProps }) {
    const {
        forList,
        inject,
        isPart,
        offer,
        trimTitle,
    } = offerProps

    const classicSpecLayout = !Boolean(trimTitle)

    if (classicSpecLayout && offer.type === 'car_ad') return (
        <OfferFeatureGrid
            offer={offer}
            fieldIcons={[
                new FieldIcon('production_year', 'calendar'),
                new FieldIcon('fuel', 'fuel'),
                new FieldIcon('data.gearbox', 'gearbox'),
                new FieldIcon('mileage', 'road', 'km'),
                new FieldIcon('data.displacement_capacity', 'capacity', 'cm³'),
            ]}
            forList={forList}
        />
    )

    if (classicSpecLayout) return (
        <OfferShortSpec
            inject={inject?.spec}
            specTextArray={getSpec(offer, true)}
            oneliner={isPart}
        />
    )

    return (
        <OfferFeatureGrid
            offer={offer}
            fieldIcons={[
                new FieldIcon('production_year', 'calendar'),
                new FieldIcon('fuel', 'fuel'),
                new FieldIcon('data.gearbox', 'gearbox'),
                new FieldIcon('mileage', 'road', 'km'),
            ]}
            forList={forList}
        />
    )
}

function ResolveTitleLayout ({ offerProps }) {
    const {
        inject,
        offer,
        trimTitle,
    } = offerProps

    const classicTitleLayout = !Boolean(trimTitle)
    const [,caption, bodyType] = listAlt(offer)

    return classicTitleLayout
        ? <>
            <Title caption={`${caption} ${bodyType}`.trim()} {...offerProps} />
            <Equipment {...offerProps} />
          </>
        : <>
            <Title caption={`${caption} ${trimTitle}`.trim()} {...offerProps} />
            <OfferShortSpec
                inject={inject?.spec}
                specTextArray={getAltSpec(offer, trimTitle)}
                style={{opacity:0.5,lineHeight:0.7}}
            />
          </>
}

function BuyOfferListItem(offerProps) {
    const {
        featured,
        forList,
        linkWithHost,
        offer,
        inject,
        isPart,
        ...rest
    } = offerProps
	const link = offerLinkBuySlugified(offer)
	const specTextArray = getBuySpec(offer, true)
	const [, caption, bodyType] = buyCaption(offer)
	const titleCaption = `${caption} ${bodyType}`.trim()
	const className = mix([
		styles[forList ? 'list-item' : 'grid-item'],
		!featured && isHighlighted(offer) && styles.highlighted,
	])

	return (
        (<Link href={linkWithHost ? HOST + link : link} prefetch={false} {...rest}>

            <article className={className}>
                <div className={mix([styles.body, styles.buy])}>
                    {forList ? (
                        <>
                            <div className={styles['list-body']}>
                                <div>
                                    <Title caption={titleCaption} pre="Kupię" {...offerProps} />
                                    <Equipment {...offerProps} />
                                </div>
                                <div className={styles['price-col']}>
                                    <IsNew offer={offer} {...offerProps} />
                                    <PriceDetails inject={inject} {...offerProps} />
                                    <Installment {...offerProps} />
                                </div>
                            </div>
                            <hr />
                            <div className={styles['row']}>
                                <OfferShortSpec
                                    inject={inject?.spec}
                                    specTextArray={specTextArray}
                                    oneliner={isPart}
                                    teal
                                />
                                <Location {...offerProps} />
                            </div>
                        </>
                    ) : (
                        <>
                            <Title caption={titleCaption} pre="Kupię" {...offerProps} />
                            <Equipment {...offerProps} />
                            <PriceDetails inject={inject} {...offerProps} />
                            <Installment {...offerProps} />
                            <br />
                            <OfferShortSpec
                                inject={inject?.spec}
                                specTextArray={specTextArray}
                                oneliner={isPart}
                                teal
                            />
                            <hr />
                            <div className={styles['row']}>
                                <span />
                                <Location {...offerProps} />
                            </div>
                        </>
                    )}
                </div>
            </article>

        </Link>)
    );
}

function FeaturedItem(props) {
	return (
		<OfferListItem {...props} featured={true} nohighlighttag="true" />
	)
}

function GridItem(props) {
	return <OfferListItem {...props} />
}

function ListItem(props) {
	return <OfferListItem {...props} forList={true} />
}

function isHighlighted(offer) {
	const onListTop = Boolean(offer?.promotion?.search_list_top?.on)
	return onListTop
}

function buyCaption(offer) {
	const caption = buyCaptionFor(offer)
	return [caption, caption, '', '']
}

OfferListItem.Featured = FeaturedItem
OfferListItem.GridItem = GridItem
OfferListItem.ListItem = ListItem

export default OfferListItem
export {
    FeaturedItem,
    GridItem,
    ListItem,
    OfferListItem,
}
