import { OfferImage as ImageForOffer } from '@components/image/OfferImage'
import Inscription from '@components/display/offer/Inscription'
import Icon from '@components/shared/Icon'
import useT from '@hooks/useTranslation'
import formatPrice from '@utils/price/format'
import formatPriceRange from '@utils/price/formatPriceRange'
import { netPriceToGross } from '@utils/offers/netPriceToGross'

import styles from './OfferListItemNew.module.css'

const LIST_VARIANT_SRC_SET = {
	'1x': 'list',
	'2x': 'x0grid',
}

const GRID_VARIANT_SRC_SET = {
	'2048w': 'x2grid',
	'1024w': 'x1grid',
	'568w': 'x0grid',
	'300w': 'list',
	'124w': 'thumb',
}

const GRID_VARIANT_SRC_SET_SIZES = `(max-width: 400px) 300w, (max-width: 500px) 586w, (max-width: 1180px) 1024w, 586w`

function Equipment({ equipment }) {
	return equipment ? (
		<h3 className={styles.equipment}>{equipment}</h3>
	) : null
}

function Firm({ isFirm, user }) {
	const t = {
		firm: useT('firmowa'),
		offer: useT('Oferta'),
		private: useT('prywatna'),
	}

	const firm =
		typeof user === 'object'
			? isFirm
				? `${t.offer} ${t.firm}`
				: `${t.offer} ${t.private}`
			: null

	return firm ? <div className={styles.firm}>{firm}</div> : <span />
}

function Location({ location }) {
	return location ? (
		<div className={styles.location}>
			<Icon style={{ fontSize: '15rem', margin: 0 }}>
				location_on
			</Icon>{' '}
			{location}
		</div>
	) : null
}

function OfferImage({ alt, forList, inscription, offer, reserved }) {
	return (
		<div className={styles.image}>
			{(inscription || reserved) && (
				<Inscription text={inscription} reserved={reserved} />
			)}
			<ImageForOffer
				alt={alt}
				offer={offer}
				preferredOrder={0}
				preferredVariant="list"
				variantSrcSet={
					forList ? LIST_VARIANT_SRC_SET : GRID_VARIANT_SRC_SET
				}
				variantSrcSetSizes={
					forList ? undefined : GRID_VARIANT_SRC_SET_SIZES
				}
			/>
		</div>
	)
}

function PriceDetails({ forList, inject, isBuy, offer }) {
	let price

	if (isBuy) {
		price = formatPriceRange(offer.price, ' ')
	} else {
		const [_,grossPrice] = netPriceToGross(offer)
		price = formatPrice(grossPrice, ' ')
	}

	return (
		<div className={styles.details}>
			{forList && inject?.details}
			<span className={styles.price}>
				{price} {offer.price?.currency || 'PLN'}
			</span>
			{!forList && inject?.details}
		</div>
	)
}

function Title({ caption, forList, isPart, pre, offer }) {
	const _pre = useT(pre)
	return (
		<h2
			className={isPart ? styles['part-title'] : styles.title}
		>
			<span>
				{pre && <span className={styles.teal}>{_pre}</span>} {caption}
			</span>
			{!forList && <IsNew offer={offer} />}
		</h2>
	)
}

function Installment({ installment }) {
	const t = {
		installment: useT('Rata'),
		mth: useT('mc'),
	}

	return installment ? (
		<div className={styles.installment}>
			{t.installment} {installment} PLN/{t.mth}
		</div>
	) : null
}

function IsNew({ before, offer }) {
	const { _new: isNew } = offer || {}
	const _new = useT('Nowy')
	if (!isNew) return null
	return isNew ? (
		<>
			{before}
			<span className={styles.badge}>{_new}</span>
		</>
	) : null
}

function extractOfferData(props) {
	const { forList, offer, ...rest } = props || {}
	const { data, price, tracking, user } = offer || {}
	const { equipment, inscription, dealer: isDealer } = data || {}
	const { installment } = price || {}
	const { reserved } = tracking || {}
	const { address, dealer } = user || {}
	const { firm_nip: firmNip } = dealer || {}
	const { city: userCity } = address || {}
	const { city: offerCity, trim } = tracking || {}
	const { title: trimTitle } = trim || {}
	const location = noStringGibberish(offerCity || userCity)

	const isBuy = offer.type === 'buy_ad' || offer.isBuy
	const isPart = offer.type === 'part_ad'
	const isFirm = isPart
		? Boolean(dealer && isDealer)
		: Boolean(firmNip)
	const isNew = Boolean(offer._new)

	return ({
			...rest,

			equipment,
			forList,
			inscription,
			installment,
			isBuy,
			isFirm,
			isNew,
			isPart,
			location,
			offer,
			reserved,
			trimTitle,
			user,
	})
}

function noStringGibberish(string) {
	if (typeof string !== 'string') return null
	const trimmed = string.trim()
	if (['-'].includes(trimmed)) return null
	return trimmed
}

export {
	Equipment,
	Firm,
	Installment,
	IsNew,
	Location,
	OfferImage,
	PriceDetails,
	Title,

	extractOfferData,
}
