const SORT_OPTIONS = [
    { t: 'Najnowsze', value: 'najnowsze' },
    { t: 'Popularne', value: 'popularne' },
    { t: 'Najtańsze', value: 'najtansze' },
    { t: 'Najdrozsze', value: 'najdrozsze' },
    { t: 'W Pobliżu', value: 'blisko' },
]

const NO_LOCATION_SORT_OPTIONS = SORT_OPTIONS.slice(0, -1)

export default SORT_OPTIONS
export {
    NO_LOCATION_SORT_OPTIONS,
}